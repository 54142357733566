<template>
    <div class="view-script-main">
        <div class="view-script-content">
            <el-scrollbar class="view-script-list" :native="false">
                <div class="view-script-item">
                    <div class="view-script-item-top">
                        <div class="view-script-img">
                            <img :src="addScriptForm.cover_url" alt="">
                        </div>
                        <div class="view-script-right">
                            <div class="view-script-name">脚本名称：<span>{{addScriptForm.name}}</span></div>
                            <div class="view-script-fenlei">脚本分类：<span>{{addScriptForm.classification_name}}</span></div>
                            <div class="view-script-jianjie">脚本简介：<span>{{addScriptForm.script_introduction}}</span></div>
                        </div>
                    </div>
                    <div class="view-script-body">
                        <div class="view-script-body-title">脚本内容</div>
                        <div class="view-script-body-content" v-html="addScriptForm.script_content"></div>
                    </div>
                    <div class="view-script-footer">
                        <el-table :data="addScriptForm.camera_lens" border style="width: 100%; flex: 1"
                                  size="medium"
                                  :header-cell-style="{fontWeight: 'normal', color: '#222', background: '#ECEBFF',height:'50px'}"
                                  :cell-style="{fontSize: '14px',color: '#222'}" :row-class-name="tableRowClassName">
                            <el-table-column prop="number" label="镜头" align="center" width="60"></el-table-column>
                            <el-table-column prop="commentary" label="景别" align="center"></el-table-column>
                            <el-table-column prop="shot_method" label="拍摄方向" align="center"></el-table-column>
                            <el-table-column prop="carrying_mirror" label="运镜" align="center"></el-table-column>
                            <el-table-column prop="frame" label="拍摄内容" align="center"></el-table-column>
                            <el-table-column prop="music" label="音乐/字幕" align="center"></el-table-column>
                            <el-table-column prop="time" label="时间" align="center" width="160"></el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "adminViewScriptCreation",
        data(){
            return{
                id:this.$route.query.id||'',
                addScriptForm: {
                    name: '',
                    script_introduction: '',
                    cover_url: null,
                    classification_name: '',
                    // classification_name: '',
                    script_content: '',
                    camera_lens: []
                },
            }
        },
        mounted() {
            this.getScriptList()
        },
        methods:{
            tableRowClassName({row, rowIndex}) {
                if (rowIndex === 1) {
                    return 'warning-row';
                } else if (rowIndex === 3) {
                    return 'warning-row';
                }
                return '';
            },
            // 查看脚本详情
            getScriptList() {
                let param = {
                    status: 1,
                    id:this.$route.query.id
                }
                this.$http.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.addScriptForm = res.data
                            this.addScriptForm.camera_lens = JSON.parse(res.data.camera_lens)
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            },
        }
    }
</script>

<style scoped lang="scss">
    .view-script-main{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
        .view-script-content{
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            border-radius: 10px;
            background-color: #fff;
            .view-script-list {
                flex: 1;
                margin: 10px 0;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .view-script-item{
                    padding: 30px 30px 0;
                    .view-script-item-top{
                        display: flex;
                        align-items: center;
                        .view-script-img{
                            width: 392px;
                            height: 226px;
                            margin-right: 20px;
                            img{
                                max-width: 100%;
                                height: 226px;
                            }
                        }
                        .view-script-right{
                            flex: 1;
                            width: 1%;
                            .view-script-name{
                                font-size: 20px;
                                color: #333;
                            }
                            .view-script-fenlei{
                                margin: 40px 0;
                                font-size: 16px;
                                color: #666;
                            }
                            .view-script-jianjie{
                                font-size: 16px;
                                color: #666;
                            }
                        }
                    }
                    .view-script-body{
                        display: flex;
                        flex-direction: column;
                        border: 2px solid #574DED;
                        box-shadow: 0px 10px 0px 0px #ECEBFF;
                        border-radius:25px;
                        margin:42px 0;
                        .view-script-body-title{
                            width: 160px;
                            height: 32px;
                            background: #584EEE;
                            border-radius: 16px;
                            line-height: 32px;
                            text-align: center;
                            color: #fff;
                            margin: -12px 0 0 24px;
                            position: relative;
                            &:before{
                                content: "";
                                background: #fff;
                                -webkit-border-radius: 50%;
                                border-radius: 50%;
                                width: 8px;
                                height: 8px;
                                position: absolute;
                                top: 12px;
                                left: 20px
                            }
                            &:after{
                                content: "";
                                background: #fff;
                                -webkit-border-radius: 50%;
                                border-radius: 50%;
                                width: 8px;
                                height: 8px;
                                position: absolute;
                                top: 12px;
                                right: 20px
                            }
                        }
                        .view-script-body-content{
                            display: flex;
                            min-height: 100px;
                            padding-left: 22px;
                        }
                    }
                }
            }
        }
        ::v-deep.el-table{
            .warning-row {
                background: #ECEBFF;
            }
        }
    }
</style>